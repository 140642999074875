import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { BnNgIdleService } from './bn-ng-idle.service';
import { BASE_API_URL_TOKEN } from './injectors';
import { FacadeService } from './facade/facade.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  constructor(private httpClient: HttpClient, private bnIdle: BnNgIdleService
    , private _facadeService: FacadeService, @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) {

  }
  private config: { version: string };
  ngOnInit() {
    var timeout = this.baseUrl["API_ENDPOINTS"]["Timeout"];
    this.bnIdle.startWatching(timeout).subscribe((res) => {
      if (res) {
        var userId = parseInt(sessionStorage.getItem('userId'));
        var appId = parseInt(sessionStorage.getItem('appliId'));
        var modelData = {
          "userId": userId,
          "applicationId": appId,
          "source": "logout"
        };
        this._facadeService.AddUserTracking(modelData).subscribe(
          (data) => {
            console.log('session expired');
            sessionStorage.clear();
            location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
          },
          (error) => {
            console.log(error);
          }
        );

      }
    });
    this.config = null;//require("../assets/Version.json");
    console.log(this.config.version);
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.httpClient
      .get<{ version: string }>("assets/Version.json", { headers })
      .subscribe(config => {
        console.log(config);
        if (config.version !== this.config.version) {
          this.httpClient
            .get("", { headers, responseType: "text" })
            .subscribe(() => location.reload());
        }
      });
  }
}

export const CONSTANTS = {
  'plm': 'b0c1a56255314055bd0931818c1fffa9',
  'ums': 'b0c1a56255314055bd0931818c1fffa9',
  'prt': 'b0c1a56255314055bd0931818c1fffa9',
  'authKey': 'b0c1a56255314055bd0931818c1fffa9'
}
export const API_ENDPOINTS = {
  "UMSAPI": "https://kinetic-qa.azure-api.net/ums/api/",
  "PLMAPI": "https://kinetic-qa.azure-api.net/plm/api/",
  "UMSAuthAPI": "https://kinetic-qa.azure-api.net/auth/api/"
}
